import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const Container = styled.header`
	display: flex;
	justify-content: center;
	padding: 200px 0 80px;
`;

const Logo = styled( AniLink )`
	width: 90%;
	max-width: 500px;

	img {
		display: block;
	}
`;

const Header = ({ siteTitle }) => {
	const data = useStaticQuery( graphql`
		query {
			logo: file(relativePath: { eq: "logo.png" }) {
				publicURL
			}
		}
	` );

	return (
		<Container>
			<Logo
				fade
				to="/"
			>
				<img
					src={ data.logo.publicURL }
					alt={ siteTitle }
					title={ siteTitle }
				/>
			</Logo>
		</Container>
	);
};

Header.propTypes = {
	siteTitle: PropTypes.string,
};

Header.defaultProps = {
	siteTitle: '',
};

export default Header;
