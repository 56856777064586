/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
*
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import Header from './header';
import Footer from './footer';

import '../scss/site.scss';

const Container = styled.div`
	color: #fff;
`;

const Main = styled.main`
`;

const Layout = ({ children }) => {
	const data = useStaticQuery( graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	` );

	return (
		<Container>
			<Header siteTitle={ data.site.siteMetadata.title } />
			<Main>{ children }</Main>
			<Footer />
		</Container>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
