import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import ReactSVG from 'react-svg';

const Container = styled.footer`
	padding: 50px 5% 30px;
	font-size: 0.75rem;
	text-align: center;
`;

const Social = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
	font-size: 0.875rem;

	span {
		margin: 0 5px;
	}
`;

const Email = styled.a`
	color: #fff;

	:hover {
		opacity: 0.8;
	}
`;

const Twitter = styled.a`
	display: flex;
	align-items: center;
	margin-right: 35px;
	color: #fff;

	:hover {
		opacity: 0.8;
	}

	svg {
		width: 20px;
		height: 14px;
		margin-right: 5px;
	}

	.twitter {
		fill: #fff;
	}
`;

const Links = styled.div`
	margin-bottom: 10px;

	a {
		color: #fff;

		:hover {
			opacity: 0.8;
		}
	}

	span {
		margin: 0 5px;
	}
`;

const Address = styled.p`
	margin-bottom: 5px;
`;

const Copy = styled.p``;

const Footer = ({ siteTitle }) => {
	const data = useStaticQuery( graphql`
		query {
			email: file(relativePath: { eq: "email.svg" }) {
				publicURL
			}

			twitter: file(relativePath: { eq: "twitter.svg" }) {
				publicURL
			}
		}
	` );

	return (
		<Container>
			<Social>
				<Email href="mailto:team@digitalarm.co.uk">
						Email Us
				</Email>
			</Social>

			<Links>
				<AniLink fade to="/privacy">
					Privacy
				</AniLink>

				<span>&middot;</span>

				<AniLink fade to="/terms">
					Terms
				</AniLink>
			</Links>

			<Address>
				{ 'Registered Address: Digital Arm Ltd, 21 Angel Hill, Tiverton, England, EX16 6PE' }
			</Address>

			<Copy>
				{ '© Digital Arm ' + new Date().getFullYear() + ' all rights reserved · VAT No. 200 0028 04 · Company Reg. 08591375' }
			</Copy>
		</Container>
	);
};

Footer.propTypes = {
	siteTitle: PropTypes.string,
};

Footer.defaultProps = {
	siteTitle: '',
};

export default Footer;
